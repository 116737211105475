@font-face {
  font-family: "Avalon-Book";
  src: url("./Avalon-Medium.eot");
  src: url("./Avalon-Medium.woff2") format("woff2"),
    url("./Avalon-Medium.woff") format("woff"),
    url("./Avalon-Medium.ttf") format("truetype"),
    url("./Avalon-Medium.svg#Avalon-Medium") format("svg"),
    url("./Avalon-Medium.eot?#iefix") format("embedded-opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Avalon-Book";
  src: url("./Avalon-Demi.eot");
  src: url("./Avalon-Demi.woff2") format("woff2"),
    url("./Avalon-Demi.woff") format("woff"),
    url("./Avalon-Demi.ttf") format("truetype"),
    url("./Avalon-Demi.svg#Avalon-Demi") format("svg"),
    url("./Avalon-Demi.eot?#iefix") format("embedded-opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Avalon-Book";
  src: url("./Avalon-Book.eot");
  src: url("./Avalon-Book.woff2") format("woff2"),
    url("./Avalon-Book.woff") format("woff"),
    url("./Avalon-Book.ttf") format("truetype"),
    url("./Avalon-Book.svg#Avalon-Book") format("svg"),
    url("./Avalon-Book.eot?#iefix") format("embedded-opentype");
  font-weight: 300;
  font-style: normal;
}
